import * as React from "react"
import { Link } from "gatsby"

// Shared Components
import Footer from '../components/shared/footer/Footer'
import Header from '../components/shared/header/Header'

const browser = typeof window !== "undefined" && window;

// markup
const NotFoundPage = () => {
  return (
      browser && ( <div>
        <Header/>
        <main
            className="min-h-screen bg-cover bg-top sm:bg-top"
            style={{
              backgroundImage:
                  "url('https://images.unsplash.com/photo-1542273917363-3b1817f69a2d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1748&q=80')",
            }}
        >
          <div className="max-w-7xl mx-auto px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48">
            <p className="text-sm font-semibold text-black text-opacity-50 uppercase tracking-wide">404 error</p>
            <h1 className="mt-2 text-3xl font-extrabold text-white tracking-tight sm:text-3xl">
              The page you're looking for doesn't seem to exist.
            </h1>
            <p className="mt-2 text-lg font-medium text-black text-opacity-50">
              Let's put you back on the right path.
            </p>
            <div className="mt-6">
              <Link
                  to="/"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-black text-opacity-75 bg-white bg-opacity-75 sm:bg-opacity-25 sm:hover:bg-opacity-50"
              >
                Go Home
              </Link>
            </div>
          </div>
        </main>
        <Footer/>
      </div>
      )
  )
}

export default NotFoundPage
